import { AxiosResponse } from 'axios';

import { Config } from '@/types/api/config';

import { API } from '@/api-requests/instance';

const { axios } = API;

const getConfig = (): Promise<AxiosResponse<Config>> =>
  axios.get<Config>('/v2/econsults/config');

export const configAPI = {
  getConfig
};
