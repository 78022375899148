import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { FormErrors } from '@/components/layouts/FormLayout/validation';

import { snackbarActions } from '@/store/snackbar';

import { gerErrorMessage, getFormErrors } from '@/utils/format';

interface ReducerArgs {
  errorMessage: string;
  formErrors?: FormErrors | undefined;
}

type Reducer = (args: ReducerArgs) => PayloadAction<ReducerArgs>;

interface HandleErrorArgs {
  error: unknown;
  reducer: Reducer;
  withFormErrors?: boolean;
}

export function* handleError({
  error,
  reducer,
  withFormErrors
}: HandleErrorArgs) {
  const errorMessage: string = yield call(gerErrorMessage, error);
  const formErrors: FormErrors | undefined = yield call(getFormErrors, error);

  if (reducer) {
    if (withFormErrors) {
      yield put(reducer({ errorMessage, formErrors }));
    } else {
      yield put(reducer({ errorMessage }));
    }
  }

  yield put(snackbarActions.showAlert({ type: 'error', text: errorMessage }));
}
