import { AxiosResponse } from 'axios';

import { Discount } from '@/types/api/discount';

import { API } from '@/api-requests/instance';

const { axios } = API;

const getDiscount = (code: string): Promise<AxiosResponse<Discount>> =>
  axios.get<Discount>(`/v2/discounts/${code}`);

export const discountAPI = {
  getDiscount
};
