import { AxiosError, AxiosResponse } from 'axios';
import format from 'date-fns/format';

import { RequestError } from '@/types/common';
import { FormErrors } from '@/components/layouts/FormLayout/validation';

import { COUNTRIES } from '@/utils/vars';
import i18n from '@/utils/i18n';

export const getFormattedDate = (date: Date | string): string => {
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  return format(dateObj, 'dd/MM - hh:mm');
};

export const getPhoneCountryCode = (country: COUNTRIES): string => {
  switch (country) {
    case COUNTRIES.BE:
      return '32';
    case COUNTRIES.FR:
      return '33';
    case COUNTRIES.ES:
      return '34';
    case COUNTRIES.IT:
      return '39';
    default:
      return '';
  }
};

export const gerErrorMessage = (error: unknown): string => {
  if ((error as AxiosError<RequestError>)?.response?.data?.message) {
    if ((error as AxiosError<RequestError>)?.response?.data?.errors) {
      const errors = Object.values(
        (
          (error as AxiosError<RequestError>)
            .response as AxiosResponse<RequestError>
        ).data.errors
      ).join(' ');

      return `${
        (error as AxiosError<RequestError>)?.response?.data?.message
      } ${errors}`;
    }
    return (error as AxiosError<RequestError>)?.response?.data
      ?.message as string;
  }
  if ((error as Error)?.message) {
    return (error as Error).message;
  }
  return error as string;
};

export const getFormErrors = (error: unknown): FormErrors | undefined => {
  const responseErrors = (error as AxiosError<RequestError>)?.response?.data
    ?.errors;

  if (responseErrors) {
    Object.keys(responseErrors).map((key) => {
      (responseErrors[key] as unknown as string) =
        responseErrors[key].join(' ');
      return key;
    });

    return responseErrors;
  }

  return undefined;
};

export const formatCurrency = new Intl.NumberFormat(i18n.language, {
  style: 'currency',
  currency: 'EUR'
}).format;
