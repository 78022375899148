import { FACEBOOK_PIXEL_ID } from './config';

// Hashing algorithm provided by MDN
// https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
async function sha256(message: string) {
  const msgUint8 = new TextEncoder().encode(message.trim().toLocaleLowerCase());
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  return hashHex;
}

interface SendUserDataToFBParams {
  em: string;
  ph: string;
  fn: string;
  ln: string;
  external_id: string;
  db: string;
  ct: string;
  zp: string;
  country: string;
}

const formatParams = async (
  params: SendUserDataToFBParams
): Promise<SendUserDataToFBParams> => {
  const hashedParamsEntries = await Promise.all(
    Object.entries(params).map(async ([key, value]) => [
      key,
      await sha256(value)
    ])
  );

  const hashedParams = hashedParamsEntries.reduce<SendUserDataToFBParams>(
    (acc, [key, value]) => {
      acc[key as keyof SendUserDataToFBParams] = value;
      return acc;
    },
    {} as SendUserDataToFBParams
  );

  return hashedParams;
};

export const sendUserDataToFB = async (params: SendUserDataToFBParams) => {
  try {
    if (
      process.env.NODE_ENV === 'production' &&
      window.fbq &&
      FACEBOOK_PIXEL_ID
    ) {
      const formattedParams = await formatParams(params);
      window.fbq('init', FACEBOOK_PIXEL_ID, formattedParams);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};
