import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/nextjs';
import { ErrorBoundary as ErrorBoundaryUI } from '@joovence/ui/components/ErrorBoundary/ErrorBoundary';
import { ErrorBoundaryLogger } from '@joovence/ui/components/ErrorBoundary/helpers';

interface ErrorBoundaryProps {
  children: ReactNode;
}

export const ErrorBoundary: FC<ErrorBoundaryProps> = ({ children }) => {
  const { t } = useTranslation('misc');

  const fallback = <h1>{t('errors.boundary')}</h1>;

  const logger: ErrorBoundaryLogger = (error) => {
    Sentry.captureException(error);
  };

  return (
    <ErrorBoundaryUI fallback={fallback} logger={logger}>
      {children}
    </ErrorBoundaryUI>
  );
};
