import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios';

import { BASE_URL } from '@/utils/config';
import { CSRF_URL } from '@/utils/vars';

const createAPI = (config?: AxiosRequestConfig) => ({
  axios: axios.create(config)
});

const refreshCSRFCookie = (): Promise<AxiosResponse> => axios.get(CSRF_URL);

const addResponseInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      // Refresh CSRF cookie if it has expired
      if (
        error.response?.status === 419 &&
        error.response.config.url !== CSRF_URL
      ) {
        refreshCSRFCookie();
      }
      return Promise.reject(error);
    }
  );
};

export const API = createAPI({
  baseURL: BASE_URL,
  withCredentials: true
});

export const API_S3 = createAPI();

addResponseInterceptor(API.axios);
