import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { LANGUAGES } from '@/utils/vars';

import { en } from '@/lang/en';
import { fr } from '@/lang/fr';
import { es } from '@/lang/es';
import { it } from '@/lang/it';

const resources = {
  en,
  fr,
  es,
  it
};

i18n.use(initReactI18next).init({
  resources,
  lng: LANGUAGES.FR,

  interpolation: {
    escapeValue: false
  }
});

export default i18n;
