import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { AxiosRequestHeaders, AxiosResponse } from 'axios';
import format from 'date-fns/format';

import { Comment, Econsult, PhotosReupload } from '@/types/api/eConsult';

import { eConsultAPI, FormPostType } from '@/api-requests/eConsult';
import { storageAPI } from '@/api-requests/storage';

import {
  eConsultActions,
  EconsultState,
  NormalizedEconsult
} from '@/store/eConsult';
import { snackbarActions } from '@/store/snackbar';

import i18n from '@/utils/i18n';
import { handleError } from '@/utils/redux';
import { cookieOptions, setCookie } from '@/utils/cookie';
import { ENCRYPTED_UUID_COOKIE_KEY } from '@/utils/config';
import { sendUserDataToFB } from '@/utils/analytics';

// ****************************************************************************
// Fetch eConsult saga
// ****************************************************************************
function* fetchEconsultSaga({
  payload: { encryptedUuid, callback }
}: PayloadAction<{ encryptedUuid: string; callback?: () => void }>) {
  try {
    const { data }: AxiosResponse<NormalizedEconsult> = yield call(
      eConsultAPI.getEconsult,
      { encryptedUuid }
    );

    if (data.requested_new_photos_at) {
      data.reuploadDict = data.requested_photos?.reduce<Record<string, null>>(
        (acc, val) => {
          acc[val] = null;
          return acc;
        },
        {}
      );
    }

    yield put(eConsultActions.eConsultFetchSucceeded(data));

    yield call(
      setCookie,
      ENCRYPTED_UUID_COOKIE_KEY,
      JSON.stringify(data.key),
      cookieOptions
    );

    if (callback) {
      yield call(callback);
    }
  } catch (error) {
    yield call(handleError, {
      error,
      reducer: eConsultActions.eConsultFetchFailed
    });
  }
}

// ****************************************************************************
// Create or update eConsult saga
// ****************************************************************************
function* createOrUpdateEconsultSaga({
  payload: { form, callback }
}: PayloadAction<{
  form: FormPostType;
  callback?: (eConsult: Econsult) => void;
}>) {
  try {
    const { uuid }: Econsult = yield select(
      ({ eConsult }: { eConsult: EconsultState }) => eConsult.eConsult
    );
    const { data }: AxiosResponse<Econsult> = yield call(
      uuid ? eConsultAPI.updateEconsult : eConsultAPI.createEconsult,
      { form, uuid }
    );

    yield put(eConsultActions.eConsultCreateOrUpdateSucceeded(data));

    if (!uuid) {
      yield call(
        setCookie,
        ENCRYPTED_UUID_COOKIE_KEY,
        JSON.stringify(data.key),
        cookieOptions
      );
    }

    if (callback) {
      yield call(callback, data);
    }

    if (data.status === 'complete') {
      yield call(sendUserDataToFB, {
        em: data.email as string,
        ph: data.mobile as string,
        fn: data.firstname,
        ln: data.lastname,
        external_id: data.uuid,
        db: format(new Date(data.date_of_birth as string), 'yyyyMMdd'),
        ct: data.address.city as string,
        zp: data.address.postal_code as string,
        country: data.address.country as string
      });
    }
  } catch (error) {
    yield call(handleError, {
      error,
      reducer: eConsultActions.eConsultCreateOrUpdateFailed,
      withFormErrors: true
    });
  }
}

// ****************************************************************************
// Continue eConsult later saga
// ****************************************************************************
function* continueEconsultLaterSaga({
  payload: { uuid, form, callback }
}: PayloadAction<{ uuid: string; form: FormPostType; callback?: () => void }>) {
  try {
    const { data }: AxiosResponse<Econsult> = yield call(
      eConsultAPI.continueLater,
      { uuid, form }
    );
    yield put(eConsultActions.eConsultContinueLaterSucceeded(data));
    yield put(
      snackbarActions.showAlert({
        type: 'success',
        text: i18n.t('eConsult:continueLater.success')
      })
    );

    if (callback) {
      yield call(callback);
    }
  } catch (error) {
    yield call(handleError, {
      error,
      reducer: eConsultActions.eConsultContinueLaterFailed
    });
  }
}

// ****************************************************************************
// Comment with practitioner saga
// ****************************************************************************
function* commentSaga({
  payload: { text, callback }
}: PayloadAction<{ text: string; callback?: () => void }>) {
  try {
    const content = text.trim();
    if (content) {
      const { uuid }: Econsult = yield select(
        ({ eConsult }: { eConsult: EconsultState }) => eConsult.eConsult
      );

      const { data }: AxiosResponse<Comment> = yield call(
        eConsultAPI.postComment,
        { econsult_uuid: uuid, content: text }
      );
      yield put(eConsultActions.eConsultCommentSucceeded(data));
      if (callback) {
        yield call(callback);
      }
    } else {
      yield put(
        snackbarActions.showAlert({
          type: 'info',
          text: i18n.t('status:messages.empty')
        })
      );
    }
  } catch (error) {
    yield call(handleError, {
      error,
      reducer: eConsultActions.eConsultCommentFailed
    });
  }
}

const uploadPhoto = async (file: File): Promise<string> => {
  // Get photo link
  const {
    data: {
      url,
      key: photoLink,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      headers: { Host, ...S3Headers }
    }
  } = await eConsultAPI.getPhotoURL({
    content_type: file.type || '',
    bucket: '',
    expires: '',
    visibility: ''
  });

  // Upload file to S3
  await storageAPI.uploadFile({
    url,
    file,
    config: {
      headers: S3Headers as unknown as AxiosRequestHeaders
    }
  });

  return photoLink;
};

// ****************************************************************************
// Upload photo saga
// ****************************************************************************
function* uploadPhotoSaga({
  payload: { file, key, reupload = false, onError }
}: PayloadAction<{
  file: File;
  key: string;
  reupload?: boolean;
  onError?: () => void;
}>) {
  try {
    const photoLink: string = yield call(uploadPhoto, file);

    if (reupload) {
      yield put(
        eConsultActions.eConsultUploadPhotoSucceeded({ key, photoLink })
      );
    } else {
      yield put(
        eConsultActions.eConsultCreateOrUpdateRequested({
          form: { photos: { [key]: photoLink } } as FormPostType
        })
      );
      yield put(eConsultActions.eConsultUploadPhotoSucceeded());
    }
  } catch (error) {
    yield call(handleError, {
      error,
      reducer: eConsultActions.eConsultUploadPhotoFailed
    });
    if (onError) {
      yield call(onError);
    }
  }
}

// ****************************************************************************
// Reupload photos saga
// ****************************************************************************
function* reuploadPhotoSaga() {
  try {
    const { uuid, reuploadDict }: NormalizedEconsult = yield select(
      ({ eConsult }: { eConsult: EconsultState }) => eConsult.eConsult
    );

    const { data }: AxiosResponse<PhotosReupload> = yield call(
      eConsultAPI.reuploadPhotos,
      { uuid, photos: { ...reuploadDict } }
    );
    if (data.success) {
      yield put(
        eConsultActions.eConsultReuploadPhotosSucceeded(
          reuploadDict as Record<string, string | null>
        )
      );
    } else {
      yield put(
        snackbarActions.showAlert({
          type: 'error',
          text: i18n.t('status:reupload.error.request')
        })
      );
    }
  } catch (error) {
    yield call(handleError, {
      error,
      reducer: eConsultActions.eConsultReuploadPhotosFailed
    });
  }
}

export default function* watcherEconsultSaga(): Generator {
  yield takeLatest(eConsultActions.eConsultFetchRequested, fetchEconsultSaga);
  yield takeEvery(
    eConsultActions.eConsultCreateOrUpdateRequested,
    createOrUpdateEconsultSaga
  );
  yield takeLatest(
    eConsultActions.eConsultContinueLaterRequested,
    continueEconsultLaterSaga
  );
  yield takeEvery(eConsultActions.eConsultCommentRequested, commentSaga);
  yield takeEvery(
    eConsultActions.eConsultUploadPhotoRequested,
    uploadPhotoSaga
  );
  yield takeEvery(
    eConsultActions.eConsultReuploadPhotosRequested,
    reuploadPhotoSaga
  );
}
