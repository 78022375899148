import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusType } from '@/types/common';

import { STATUS } from '@/utils/vars';

interface AuthState {
  status: StatusType;
  errors?: string;
}

const initialState: AuthState = {
  status: STATUS.NOT_STARTED
};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authRequested(state) {
      state.status = STATUS.IN_PROGRESS;
      state.errors = '';
    },
    authSucceeded(state) {
      state.status = STATUS.SUCCEEDED;
      state.errors = '';
    },
    authFailed(state, { payload }: PayloadAction<{ errorMessage: string }>) {
      state.status = STATUS.FAILED;
      state.errors = payload.errorMessage;
    }
  }
});

export const authActions = auth.actions;
export default auth.reducer;
