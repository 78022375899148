import { ReactNode, FC } from 'react';
import { useViewport } from '@joovence/ui/hooks/useViewport';

import { JoovenceLogoMedium } from '@/components/common/Icons/JoovenceLogoMedium';

import { RootStyled, HeaderStyled, MainStyled } from './Styled';

interface BaseLayoutProps {
  children: ReactNode;
}

export const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
  useViewport({ delay: 500 });

  return (
    <RootStyled>
      <HeaderStyled>{JoovenceLogoMedium}</HeaderStyled>
      <MainStyled>{children}</MainStyled>
    </RootStyled>
  );
};
