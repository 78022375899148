import { AxiosResponse } from 'axios';

import {
  Econsult,
  PhotosReupload,
  PhotoUrl,
  Comment
} from '@/types/api/eConsult';
import { FormType, Photos } from '@/types/form/eConsult';

import { API } from '@/api-requests/instance';

const { axios } = API;

export type FormPostType = Omit<FormType, 'cgu' | 'pdp' | 'hasDiscount'>;

const getEconsult = ({
  encryptedUuid
}: {
  encryptedUuid: string;
}): Promise<AxiosResponse<Econsult>> =>
  axios.get<Econsult>(`/v2/econsults/${encryptedUuid}`);

const createEconsult = ({
  form
}: {
  form: FormPostType;
}): Promise<AxiosResponse<Econsult>> =>
  axios.post<Econsult>('/v2/econsults', form);

const updateEconsult = ({
  form,
  uuid
}: {
  form: FormPostType;
  uuid: string;
}): Promise<AxiosResponse<Econsult>> =>
  axios.put<Econsult>(`/v2/econsults/${uuid}`, form);

interface ContinueLaterArgs {
  form: FormPostType;
  uuid: string;
}

const continueLater = ({
  form,
  uuid
}: ContinueLaterArgs): Promise<AxiosResponse<Econsult>> =>
  axios.post<Econsult>(`/v2/econsults/${uuid}/save`, form);

const getPhotoURL = (body: {
  bucket: string;
  content_type: string;
  expires: string;
  visibility: string;
}): Promise<AxiosResponse<PhotoUrl>> =>
  axios.post('/v2/econsults/signed-storage-url', body);

const reuploadPhotos = ({
  uuid,
  photos
}: {
  uuid: string;
  photos: Photos;
}): Promise<AxiosResponse<PhotosReupload>> =>
  axios.post<PhotosReupload>(`/v2/econsults/${uuid}/new-photos`, { photos });

const postComment = ({
  content,
  econsult_uuid
}: {
  content: string;
  econsult_uuid: string;
}): Promise<AxiosResponse<Comment>> =>
  axios.post<Comment>(`/v2/econsults/${econsult_uuid}/comments`, { content });

export const eConsultAPI = {
  getEconsult,
  createEconsult,
  updateEconsult,
  continueLater,
  getPhotoURL,
  reuploadPhotos,
  postComment
};
