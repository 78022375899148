import { hostname } from 'os';

import { SSR_USER_COOKIE_KEY } from './vars';

export type CookieOptions = {
  'max-age'?: number;
  expires?: Date | number | string;
  path?: string;
  domain?: string;
  secure?: boolean;
};

type CookieOptionsKey = keyof CookieOptions;

export const setCookie = (
  name: string,
  value: string,
  options: CookieOptions = {}
): void => {
  const allOptions: CookieOptions = {
    path: '/',
    ...options
  };

  if (allOptions.expires instanceof Date) {
    allOptions.expires = allOptions.expires.toUTCString();
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(
    value
  )}`;

  const optionsKeys = Object.keys(allOptions) as CookieOptionsKey[];
  optionsKeys.forEach((optionKey) => {
    updatedCookie += `; ${optionKey}`;
    const optionValue = allOptions[optionKey];

    if (optionValue !== true) {
      updatedCookie += `=${optionValue}`;
    }
  });

  document.cookie = updatedCookie;
};

export const deleteCookie = (name: string, options: CookieOptions): void => {
  setCookie(name, '', {
    'max-age': -1,
    ...options
  });
};

export const getCookie = (
  name: string,
  ssrCookies?: string
): string | undefined => {
  if (typeof document === 'undefined' && !ssrCookies) {
    return undefined;
  }

  const matches = (ssrCookies || document.cookie).match(
    new RegExp(
      // eslint-disable-next-line no-useless-escape
      `(?:^|; )${name?.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`
    )
  );

  return matches ? decodeURIComponent(matches[1]) : undefined;
};

const getDomain = (): string => {
  const host = hostname();
  const domains = host.split('.');

  return `.${domains.slice(domains.length - 2).join('.')}`;
};

export const cookieOptions: CookieOptions = {
  path: '/',
  expires: 365,
  domain: getDomain()
};

export const cleanupSsrCookiesBeforeUnload = () => {
  window.addEventListener('beforeunload', () => {
    deleteCookie(SSR_USER_COOKIE_KEY, { path: '/' });
  });
};
