import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusType } from '@/types/common';
import { Discount } from '@/types/api/discount';

import { STATUS } from '@/utils/vars';

export interface DiscountState {
  discount: Discount;
  status: StatusType;
  errors?: string;
}

const initialState: DiscountState = {
  discount: {} as Discount,
  status: STATUS.NOT_STARTED
};

const discount = createSlice({
  name: 'discount',
  initialState,
  reducers: {
    discountFetchRequested(
      state,
      _action: PayloadAction<{ coupon: string; callback?: () => void }>
    ) {
      state.discount = {} as Discount;
      state.status = STATUS.IN_PROGRESS;
      state.errors = '';
    },
    discountFetchSucceeded(state, { payload }: PayloadAction<Discount>) {
      state.discount = payload;
      state.status = STATUS.SUCCEEDED;
      state.errors = '';
    },
    discountFetchFailed(
      state,
      { payload }: PayloadAction<{ errorMessage: string }>
    ) {
      state.status = STATUS.FAILED;
      state.errors = payload.errorMessage;
    }
  }
});

export const discountActions = discount.actions;
export default discount.reducer;
