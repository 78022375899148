import { FC, useEffect } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';

import { innerHTML } from './helpers';

export const GoogleTagManager: FC = () => {
  const { events } = useRouter();

  const pageview = (url: string) => {
    // @ts-ignore
    window.dataLayer.push({
      event: 'pageview',
      page: url
    });
  };

  useEffect(() => {
    events.on('routeChangeComplete', pageview);

    return () => {
      events.off('routeChangeComplete', pageview);
    };
  }, [events]);

  return (
    <Script
      id="google-tag-manager"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={innerHTML}
    />
  );
};
