import { call, put, takeLatest } from 'redux-saga/effects';

import { authAPI } from '@/api-requests/auth';

import { authActions } from '@/store/auth';

import { handleError } from '@/utils/redux';

function* authSaga() {
  try {
    yield call(authAPI.getCSRFCookie);
    yield put(authActions.authSucceeded());
  } catch (error) {
    yield call(handleError, { error, reducer: authActions.authFailed });
  }
}

export default function* watcherAuthSaga(): Generator {
  yield takeLatest(authActions.authRequested, authSaga);
}
