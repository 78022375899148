import { styled } from '@joovence/ui/config/styled';

export const RootStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(var(--vh, 1vh) * 100)',

  color: theme.palette.common.black,
  backgroundColor: theme.palette.common.white
}));
