import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { Discount } from '@/types/api/discount';
import { Econsult } from '@/types/api/eConsult';

import { discountAPI } from '@/api-requests/discount';

import { discountActions, DiscountState } from '@/store/discount';
import { eConsultActions } from '@/store/eConsult';

import { handleError } from '@/utils/redux';
import i18n from '@/utils/i18n';

function* fetchDiscountSaga({
  payload: { coupon, callback }
}: PayloadAction<{ coupon: string; callback?: () => void }>) {
  try {
    const { data }: AxiosResponse<Discount> = yield call(
      discountAPI.getDiscount,
      coupon
    );
    yield put(discountActions.discountFetchSucceeded(data));
    if (callback) {
      yield call(callback);
    }
  } catch (error) {
    yield call(handleError, {
      error: i18n.t('discount:error'),
      reducer: discountActions.discountFetchFailed
    });
  }
}

function* checkDiscountSaga({ payload }: PayloadAction<Econsult>) {
  const { discount_code }: Econsult = yield select(
    ({ discount }: { discount: DiscountState }) => discount.discount
  );

  if (payload.discount_code && !discount_code) {
    yield put(
      discountActions.discountFetchRequested({ coupon: payload.discount_code })
    );
  }
}

export default function* watcherDiscountSaga(): Generator {
  yield takeLatest(discountActions.discountFetchRequested, fetchDiscountSaga);
  yield takeLatest(eConsultActions.eConsultFetchSucceeded, checkDiscountSaga);
}
