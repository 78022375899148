export const JoovenceLogoMedium = (
  <svg
    width="131"
    height="14"
    viewBox="0 0 131 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 11.7033L1.63358 10.0681C2.45987 11.1062 3.28616 11.6849 4.47336 11.6849C5.80302 11.6849 6.71478 10.8214 6.71478 8.99334V0.0550232H9.27913V9.06683C9.27913 10.6836 8.77575 11.8962 7.94947 12.6954C7.12318 13.4947 5.93598 13.908 4.52084 13.908C2.3364 13.908 0.930762 12.9343 0 11.7033Z"
      fill="white"
    />
    <path
      d="M46.6143 0.0550232H49.3875L53.6805 10.6377L57.9544 0.0550232H60.6612L54.7632 13.9172H52.5502L46.6143 0.0550232Z"
      fill="white"
    />
    <path
      d="M66.6636 0.0550232H77.2914V2.23219H69.1899V5.83324H76.3796V8.0104H69.1899V11.7309H77.3958V13.908H66.6636V0.0550232Z"
      fill="white"
    />
    <path
      d="M83.2937 0.0550232H85.6111L93.2472 9.59046V0.0550232H95.707V13.8437H93.6081L85.7631 4.03271V13.8437H83.2937V0.0550232Z"
      fill="white"
    />
    <path
      d="M101.738 6.99078V6.95404C101.738 3.1417 104.691 0.0275269 108.88 0.0275269C111.435 0.0275269 112.973 0.891044 114.303 2.11283L112.736 3.85824C111.625 2.86611 110.438 2.19551 108.861 2.19551C106.23 2.19551 104.293 4.29918 104.293 6.92648V6.96322C104.293 9.58133 106.211 11.7126 108.861 11.7126C110.561 11.7126 111.653 11.0603 112.84 9.98553L114.407 11.5197C112.964 12.9711 111.378 13.8897 108.785 13.8897C104.739 13.8897 101.738 10.8399 101.738 6.99078Z"
      fill="white"
    />
    <path
      d="M120.296 0.0550232H130.895V2.23219H122.813V5.82405H129.984V7.99203H122.813V11.7033H131V13.8805H120.296V0.0550232Z"
      fill="white"
    />
    <path
      d="M25.9759 10.4541L27.4385 11.8688C26.0898 13.1733 24.3043 13.8898 22.4048 13.8898C20.4958 13.8898 18.7102 13.1733 17.3711 11.8688C14.5978 9.18641 14.5978 4.82289 17.3711 2.13129C18.7102 0.826826 20.4958 0.110291 22.4048 0.110291C24.3043 0.110291 26.0898 0.826826 27.4385 2.13129L28.3883 3.04992L26.9256 4.45543L25.9759 3.5368C25.0261 2.61816 23.7534 2.11292 22.4048 2.11292C21.0561 2.11292 19.793 2.61816 18.8242 3.54599C16.8582 5.44756 16.8582 8.54336 18.8242 10.4449C19.7835 11.3636 21.0561 11.878 22.4048 11.878C23.6775 11.878 24.8742 11.4095 25.8144 10.5735C25.8714 10.5368 25.9284 10.5001 25.9759 10.4541Z"
      fill="white"
    />
    <path
      d="M22.4046 14C20.4671 14 18.6436 13.2743 17.2854 11.9514C15.9178 10.6286 15.1674 8.87402 15.1674 7C15.1674 5.12598 15.9178 3.37139 17.2854 2.04856C18.6531 0.725722 20.4671 0 22.4046 0C24.3326 0 26.1562 0.725722 27.5238 2.04856L28.559 3.04987L26.9255 4.62074L25.8902 3.61942C24.9595 2.71916 23.7248 2.2231 22.4046 2.2231C21.0844 2.2231 19.8498 2.71916 18.9095 3.61942C17.9787 4.51969 17.4659 5.71391 17.4659 6.99081C17.4659 8.26772 17.9787 9.46194 18.9095 10.3622C19.8498 11.2625 21.0939 11.7585 22.4046 11.7585C23.6298 11.7585 24.8075 11.3084 25.7288 10.4816C25.7573 10.4633 25.7762 10.4449 25.7952 10.4357C25.8237 10.4081 25.8617 10.3898 25.8807 10.3622L25.9662 10.2612L27.6093 11.8504L27.5238 11.9331C26.1562 13.2743 24.3421 14 22.4046 14ZM22.4046 0.229659C20.5241 0.229659 18.767 0.937008 17.4564 2.21391C16.1362 3.49081 15.4049 5.19029 15.4049 7C15.4049 8.80971 16.1362 10.5092 17.4564 11.7861C18.767 13.063 20.5241 13.7703 22.4046 13.7703C24.2376 13.7703 25.9567 13.0997 27.2674 11.8688L25.9662 10.6102C25.9567 10.6194 25.9472 10.6286 25.9377 10.6286C25.9187 10.6378 25.8997 10.6562 25.8902 10.6654C24.931 11.5197 23.6963 11.9974 22.4046 11.9974C21.0275 11.9974 19.7358 11.4738 18.748 10.5276C17.7698 9.58136 17.2379 8.33202 17.2379 6.99081C17.2379 5.65879 17.7698 4.40026 18.748 3.45407C19.7263 2.50787 21.0275 1.98425 22.4046 1.98425C23.7913 1.98425 25.0829 2.49869 26.0612 3.44488L26.9255 4.28084L28.2266 3.0315L27.3623 2.19554C26.0327 0.937008 24.2756 0.229659 22.4046 0.229659Z"
      fill="white"
    />
    <path
      d="M41.4945 7.00005C41.4945 8.83733 40.7537 10.5644 39.4051 11.8688C38.0564 13.1733 36.2709 13.8898 34.3713 13.8898C32.4623 13.8898 30.6863 13.1733 29.3376 11.8688L28.3879 10.9502L29.8505 9.53549L30.7908 10.4541C31.75 11.3728 33.0227 11.8872 34.3713 11.8872C35.72 11.8872 36.9832 11.3728 37.9424 10.4541C38.9017 9.53549 39.4241 8.30452 39.4241 7.00005C39.4241 5.69559 38.9017 4.47381 37.9424 3.54599C36.9927 2.61816 35.7295 2.11292 34.3808 2.11292C33.0322 2.11292 31.769 2.61816 30.8098 3.5368C30.8098 3.5368 30.8098 3.5368 30.8003 3.54599L29.3471 2.13129C30.6958 0.826826 32.4718 0.110291 34.3808 0.110291C36.2804 0.110291 38.0659 0.826826 39.4146 2.13129C40.7632 3.42656 41.4945 5.1536 41.4945 7.00005Z"
      fill="white"
    />
    <path
      d="M34.381 14C32.453 14 30.6295 13.2743 29.2619 11.9514L28.2266 10.9501L29.8602 9.37008L30.8859 10.3714C31.8262 11.2717 33.0704 11.7677 34.381 11.7677C35.6917 11.7677 36.9359 11.2717 37.8666 10.3714C38.7974 9.48032 39.3103 8.2769 39.3103 7C39.3103 5.7231 38.7974 4.52887 37.8666 3.62861C36.9454 2.72835 35.7012 2.2231 34.381 2.2231C33.0609 2.2231 31.8262 2.71916 30.8954 3.61942L30.8005 3.71129L29.1764 2.13123L29.2619 2.04856C30.6295 0.725722 32.4435 0 34.381 0C36.3185 0 38.1326 0.725722 39.5002 2.04856C40.8679 3.3622 41.6182 5.1168 41.6182 7C41.6182 8.87402 40.8679 10.6286 39.5002 11.9514C38.1326 13.2743 36.309 14 34.381 14ZM28.559 10.9501L29.4233 11.7861C30.7435 13.063 32.5005 13.7703 34.3715 13.7703C36.2426 13.7703 37.9996 13.063 39.3198 11.7861C40.6399 10.5092 41.3712 8.80971 41.3712 7C41.3712 5.1811 40.6399 3.48163 39.3198 2.21391C38.0091 0.937008 36.2521 0.229659 34.381 0.229659C32.548 0.229659 30.829 0.900263 29.5183 2.13123L30.81 3.38976C31.7692 2.49869 33.0419 2.00262 34.3905 2.00262C35.7772 2.00262 37.0688 2.52625 38.0471 3.47244C39.0253 4.40945 39.5667 5.66798 39.5667 7C39.5667 8.33202 39.0253 9.59055 38.0471 10.5367C37.0688 11.4829 35.7677 12.0066 34.4 12.0066C33.0229 12.0066 31.7312 11.4829 30.7435 10.5367L29.8792 9.70079L28.559 10.9501Z"
      fill="white"
    />
    <path
      d="M32.4722 7.00006L31.0096 8.41476L26.9256 4.46463L28.3977 3.04993L32.4722 7.00006Z"
      fill="#EE737F"
    />
    <path
      d="M31.0097 8.58006L26.7643 4.46457L28.3979 2.89371L32.6433 7.00001L31.0097 8.58006ZM27.0967 4.46457L31.0097 8.25854L32.3109 7.00001L28.3884 3.21523L27.0967 4.46457Z"
      fill="#EE737F"
    />
    <path
      d="M29.8604 9.52621L28.3977 10.9501L24.3138 6.99996L25.7669 5.58527L29.8604 9.52621Z"
      fill="#EE737F"
    />
    <path
      d="M28.3979 11.1063L24.1525 7L25.7671 5.41995L30.022 9.52625L28.3979 11.1063ZM24.4849 7L28.3979 10.7848L29.6991 9.52625L25.7766 5.74147L24.4849 7Z"
      fill="#EE737F"
    />
  </svg>
);
