import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusType } from '@/types/common';
import { Comment, Econsult } from '@/types/api/eConsult';
import { Photos } from '@/types/form/eConsult';

import { FormPostType } from '@/api-requests/eConsult';

import { STATUS } from '@/utils/vars';
import { FormErrors } from '@/components/layouts/FormLayout/validation';

export interface NormalizedEconsult extends Econsult {
  reuploadDict?: Record<string, string | null>;
}

export interface EconsultState {
  eConsult: NormalizedEconsult;
  status: StatusType;
  errors?: string;
  formErrors?: FormErrors;
}

const initialState: EconsultState = {
  eConsult: {} as NormalizedEconsult,
  status: STATUS.NOT_STARTED
};

const eConsult = createSlice({
  name: 'eConsult',
  initialState,
  reducers: {
    // ************************************************************************
    // Fetch
    // ************************************************************************
    eConsultFetchRequested(
      state,
      _action: PayloadAction<{ encryptedUuid: string; callback?: () => void }>
    ) {
      state.status = STATUS.IN_PROGRESS;
      state.errors = '';
      state.formErrors = undefined;
    },
    eConsultFetchSucceeded(
      state,
      { payload }: PayloadAction<NormalizedEconsult>
    ) {
      state.eConsult = payload;
      state.status = STATUS.SUCCEEDED;
      state.errors = '';
      state.formErrors = undefined;
    },
    eConsultFetchFailed(
      state,
      { payload }: PayloadAction<{ errorMessage: string }>
    ) {
      state.status = STATUS.FAILED;
      state.errors = payload.errorMessage;
      state.formErrors = undefined;
    },

    // ************************************************************************
    // Create or update
    // ************************************************************************
    eConsultCreateOrUpdateRequested(
      state,
      _action: PayloadAction<{
        form: FormPostType;
        callback?: (eConsult: Econsult) => void;
      }>
    ) {
      state.status = STATUS.IN_PROGRESS;
      state.errors = '';
      state.formErrors = undefined;
    },
    eConsultCreateOrUpdateSucceeded(
      state,
      { payload }: PayloadAction<Econsult>
    ) {
      state.eConsult = payload;
      state.status = STATUS.SUCCEEDED;
      state.errors = '';
      state.formErrors = undefined;
    },
    eConsultCreateOrUpdateFailed(
      state,
      {
        payload
      }: PayloadAction<{
        errorMessage: string;
        formErrors?: FormErrors | undefined;
      }>
    ) {
      state.status = STATUS.FAILED;
      state.errors = payload.errorMessage;
      state.formErrors = payload.formErrors;
    },

    // ************************************************************************
    // Continue Later
    // ************************************************************************
    eConsultContinueLaterRequested(
      state,
      _action: PayloadAction<{
        form: FormPostType;
        uuid: string;
        callback?: () => void;
      }>
    ) {
      state.status = STATUS.IN_PROGRESS;
      state.errors = '';
    },
    eConsultContinueLaterSucceeded(
      state,
      { payload }: PayloadAction<Econsult>
    ) {
      state.eConsult = payload;
      state.status = STATUS.SUCCEEDED;
      state.errors = '';
    },
    eConsultContinueLaterFailed(
      state,
      { payload }: PayloadAction<{ errorMessage: string }>
    ) {
      state.status = STATUS.FAILED;
      state.errors = payload.errorMessage;
    },

    // ************************************************************************
    // Comment
    // ************************************************************************
    eConsultCommentRequested(
      state,
      _action: PayloadAction<{ text: string; callback?: () => void }>
    ) {
      state.status = STATUS.IN_PROGRESS;
      state.errors = '';
    },
    eConsultCommentSucceeded(state, { payload }: PayloadAction<Comment>) {
      if (Array.isArray(state.eConsult.comments)) {
        state.eConsult.comments.push(payload);
      } else {
        state.eConsult.comments = [payload];
      }
      state.status = STATUS.SUCCEEDED;
      state.errors = '';
    },
    eConsultCommentFailed(
      state,
      { payload }: PayloadAction<{ errorMessage: string }>
    ) {
      state.status = STATUS.FAILED;
      state.errors = payload.errorMessage;
    },

    // ************************************************************************
    // Upload photo
    // ************************************************************************
    eConsultUploadPhotoRequested(
      state,
      _action: PayloadAction<{
        file: File;
        key: string;
        reupload?: boolean;
        onError?: () => void;
      }>
    ) {
      state.status = STATUS.IN_PROGRESS;
      state.errors = '';
    },
    eConsultUploadPhotoSucceeded(
      state,
      { payload }: PayloadAction<{ key: string; photoLink: string } | undefined>
    ) {
      if (payload) {
        const { key, photoLink } = payload;
        if (state.eConsult.reuploadDict) {
          state.eConsult.reuploadDict[key] = photoLink;
        } else {
          state.eConsult.reuploadDict = {
            [key]: photoLink
          };
        }
      }
      state.status = STATUS.SUCCEEDED;
      state.errors = '';
    },
    eConsultUploadPhotoFailed(
      state,
      { payload }: PayloadAction<{ errorMessage: string }>
    ) {
      state.status = STATUS.FAILED;
      state.errors = payload.errorMessage;
    },

    // ************************************************************************
    // Reupload photos
    // ************************************************************************
    eConsultReuploadPhotosRequested(state) {
      state.status = STATUS.IN_PROGRESS;
      state.errors = '';
    },
    eConsultReuploadPhotosSucceeded(state, { payload }: PayloadAction<Photos>) {
      state.eConsult.files?.map((item) => {
        const newPhotoLink = payload[item.meta.angle];
        if (newPhotoLink) {
          item.url = payload[newPhotoLink] as string;
        }
        return item;
      });
      state.eConsult.requested_new_photos_at = null;
      state.status = STATUS.SUCCEEDED;
      state.errors = '';
    },
    eConsultReuploadPhotosFailed(
      state,
      { payload }: PayloadAction<{ errorMessage: string }>
    ) {
      state.status = STATUS.FAILED;
      state.errors = payload.errorMessage;
    },
    eConsultReuploadPhotosRemove(state, { payload }: PayloadAction<string>) {
      if (state.eConsult.reuploadDict && state.eConsult.reuploadDict[payload]) {
        state.eConsult.reuploadDict[payload] = null;
        state.eConsult.requested_new_photos_at = null;
        state.eConsult.requested_photos = null;
      }
      state.status = STATUS.SUCCEEDED;
      state.errors = '';
    }
  }
});

export const eConsultActions = eConsult.actions;
export default eConsult.reducer;
