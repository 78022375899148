import { fork } from 'redux-saga/effects';

import auth from './auth';
import config from './config';
import eConsult from './eConsult';
import discount from './discount';

export default function* sagas(): Generator {
  yield fork(auth);
  yield fork(config);
  yield fork(eConsult);
  yield fork(discount);
}
