/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';
import Head from 'next/head';
import { Provider as ReduxProvider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { CssBaseline, CacheProvider } from '@joovence/ui/libExports';
import { ThemeProvider } from '@joovence/ui/components/ThemeProvider/ThemeProvider';

import { AppProps } from '@/types/page';

import { store } from '@/store';

import i18n from '@/utils/i18n';
import { createEmotionCache } from '@/utils/styles';
import { initIntercom } from '@/utils/intercom';
import { cleanupSsrCookiesBeforeUnload } from '@/utils/cookie';

import { getInitialProps } from '@/ssr/getInitialProps';

import { useI18n } from '@/hooks/useI18n';

import { GoogleTagManager } from '@/components/common/GoogleTagManager/GoogleTagManager';
import { ErrorBoundary } from '@/components/common/ErrorBoundary/ErrorBoundary';
import { BaseLayout } from '@/components/layouts/BaseLayout/BaseLayout';
import { Snackbar } from '@/components/common/Snackbar/Snackbar';
import { HandleAuth } from '@/components/Auth/HandleAuth';

import '@/styles/index.css';

const cache = createEmotionCache();

function App({ Component, pageProps, ssrData }: AppProps): JSX.Element {
  useI18n();

  const getLayout = Component.getLayout || ((page) => page);
  const OuterLayout = Component.OuterLayout || BaseLayout;

  useEffect(() => {
    initIntercom();
    cleanupSsrCookiesBeforeUnload();
  }, []);

  return (
    <>
      <Head>
        <title>eConsult - Joovence</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>

      <GoogleTagManager />

      <ErrorBoundary>
        <CacheProvider value={cache}>
          <I18nextProvider i18n={i18n}>
            <ThemeProvider>
              <CssBaseline />
              <ReduxProvider store={store}>
                <HandleAuth ssrData={ssrData}>
                  <OuterLayout>
                    {getLayout(<Component {...pageProps} />)}
                  </OuterLayout>
                </HandleAuth>
                <Snackbar />
              </ReduxProvider>
            </ThemeProvider>
          </I18nextProvider>
        </CacheProvider>
      </ErrorBoundary>
    </>
  );
}

App.getInitialProps = getInitialProps;

export default App;
