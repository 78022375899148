import createCache, { EmotionCache } from '@emotion/cache';
import { FormikErrors, FormikTouched } from 'formik';
import { CardType } from '@joovence/ui/components/Card/helpers';

export const createEmotionCache = (): EmotionCache =>
  createCache({ key: 'css' });

interface GetCardType<T> {
  value: T;
  error: FormikErrors<T> | string | undefined;
  touched: FormikTouched<T> | boolean | undefined;
  color?: CardType;
}

export const getCardType = <T>({
  value,
  error,
  touched,
  color = 'lightgreen'
}: GetCardType<T>): CardType => {
  if (touched) {
    if (error) {
      return 'red';
    }
    if (value) {
      return color;
    }
  }
  return 'default';
};
