import { useEffect } from 'react';
import { useRouter } from 'next/router';

import i18n from '@/utils/i18n';
import { LANGUAGES, LANGUAGES_ARRAY } from '@/utils/vars';

export const useI18n = (): void => {
  const { locale } = useRouter();

  useEffect(() => {
    if (
      locale &&
      locale !== i18n.language &&
      LANGUAGES_ARRAY.includes(locale as LANGUAGES)
    ) {
      i18n.changeLanguage(locale);
    }
  }, [locale, i18n.language]);
};
