import {
  configureStore,
  combineReducers,
  PreloadedState
} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import sagas from '@/sagas';

import snackbar from './snackbar';
import auth from './auth';
import config from './config';
import eConsult from './eConsult';
import discount from './discount';

const reducer = combineReducers({
  snackbar,
  auth,
  config,
  eConsult,
  discount
});

export const createStore = <T>(preloadedState?: PreloadedState<T>) => {
  const sagaMiddleware = createSagaMiddleware();

  const configuredStore = configureStore({
    preloadedState,
    reducer,
    middleware: (getDefaultMiddleWare) =>
      getDefaultMiddleWare({
        serializableCheck: {
          ignoredActionPaths: [
            'payload.callback',
            'payload.file',
            'payload.onError'
          ]
        }
      }).concat(sagaMiddleware)
  });

  sagaMiddleware.run(sagas);

  return configuredStore;
};

export const store = createStore();

export type RootState = ReturnType<typeof reducer>;
