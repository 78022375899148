import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { API_S3 } from '@/api-requests/instance';

const { axios: S3 } = API_S3;

const uploadFile = ({
  url,
  file,
  config
}: {
  url: string;
  file: File;
  config?: AxiosRequestConfig;
}): Promise<AxiosResponse> => S3.put(url, file, config);

export const storageAPI = {
  uploadFile
};
