import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusType } from '@/types/common';
import { Config } from '@/types/api/config';

import { STATUS } from '@/utils/vars';

export interface ConfigState {
  config: Config;
  status: StatusType;
  errors?: string;
}

const initialState: ConfigState = {
  config: {} as Config,
  status: STATUS.NOT_STARTED
};

const config = createSlice({
  name: 'config',
  initialState,
  reducers: {
    configFetchRequested(state) {
      state.status = STATUS.IN_PROGRESS;
      state.errors = '';
    },
    configFetchSucceeded(state, { payload }: PayloadAction<Config>) {
      state.config = payload;
      state.status = STATUS.SUCCEEDED;
      state.errors = '';
    },
    configFetchFailed(
      state,
      { payload }: PayloadAction<{ errorMessage: string }>
    ) {
      state.status = STATUS.FAILED;
      state.errors = payload.errorMessage;
    }
  }
});

export const configActions = config.actions;
export default config.reducer;
