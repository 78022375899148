import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { Config } from '@/types/api/config';

import { configAPI } from '@/api-requests/config';

import { configActions } from '@/store/config';

import { handleError } from '@/utils/redux';

function* fetchConfigSaga() {
  try {
    const { data }: AxiosResponse<Config> = yield call(configAPI.getConfig);
    yield put(configActions.configFetchSucceeded(data));
  } catch (error) {
    yield call(handleError, {
      error,
      reducer: configActions.configFetchFailed
    });
  }
}

export default function* watcherConfigSaga(): Generator {
  yield takeLatest(configActions.configFetchRequested, fetchConfigSaga);
}
