import NextApp, { AppContext } from 'next/app';

import { SsrData } from '@/types/page';

import { authAPI } from '@/api-requests/auth';
import { configAPI } from '@/api-requests/config';
import { eConsultAPI } from '@/api-requests/eConsult';

import { SSR_USER_COOKIE_KEY } from '@/utils/vars';
import { ENCRYPTED_UUID_COOKIE_KEY } from '@/utils/config';
import { getCookie } from '@/utils/cookie';

export const getInitialProps = async (ctx: AppContext) => {
  let ssrData: SsrData = null;
  let uuid: string | undefined;

  const appProps = await NextApp.getInitialProps(ctx);

  const { req, res } = ctx.ctx;
  const { query } = ctx.router;

  if (!req || !res) {
    return { ...appProps, ssrData };
  }

  const isUserFetched = getCookie(SSR_USER_COOKIE_KEY, req?.headers.cookie);

  if (isUserFetched) {
    return { ...appProps, ssrData };
  }

  try {
    const { data: config } = await configAPI.getConfig();

    ssrData = {
      config,
      eConsult: null
    };

    const { cookie } = req.headers;

    const uuidInQuery = query.key;
    const uuidInCookie = cookie
      ? getCookie(ENCRYPTED_UUID_COOKIE_KEY, cookie)
      : undefined;

    if (uuidInQuery) {
      uuid = uuidInQuery as string;
    } else if (uuidInCookie) {
      uuid = JSON.parse(uuidInCookie);
    }

    if (uuid) {
      await authAPI.getCSRFCookie();

      const { data: eConsult } = await eConsultAPI.getEconsult({
        encryptedUuid: uuid
      });
      ssrData.eConsult = eConsult;
    }
    res.setHeader('Set-Cookie', `${SSR_USER_COOKIE_KEY}=true; Path=/`);
  } catch (error) {
    ssrData = {
      config: ssrData?.config || null,
      eConsult: ssrData?.eConsult || null
    };
  }

  return { ...appProps, ssrData };
};
