export const API_BASE = process.env.API_BASE as string;
export const INTERCOM_ID = process.env.INTERCOM_ID as string;
export const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY as string;
export const GOOGLE_TAG_MANAGER_ID = process.env
  .GOOGLE_TAG_MANAGER_ID as string;
export const PO_EDITOR_API_KEY = process.env.PO_EDITOR_API_KEY as string;
export const ENCRYPTED_UUID_COOKIE_KEY = process.env
  .ENCRYPTED_UUID_COOKIE_KEY as string;
export const BASE_URL = `${API_BASE}`;
export const GOOGLE_MAPS_API_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`;
export const AUTH_URL = process.env.AUTH_URL as string;
export const SENTRY_DSN = process.env.SENTRY_DSN as string;
export const FACEBOOK_PIXEL_ID = process.env.FACEBOOK_PIXEL_ID as string;
