import { AxiosResponse } from 'axios';

import { API } from '@/api-requests/instance';

import { CSRF_URL } from '@/utils/vars';

const { axios } = API;

const getCSRFCookie = (): Promise<AxiosResponse> => axios.get(CSRF_URL);

export const authAPI = {
  getCSRFCookie
};
