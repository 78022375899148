import address from './address.json';
import contacts from './contacts.json';
import eConsult from './eConsult.json';
import misc from './misc.json';
import personal from './personal.json';
import photos from './photos.json';
import questions from './questions.json';
import validation from './validation.json';
import complete from './complete.json';
import status from './status.json';
import welcome from './welcome.json';
import discount from './discount.json';

export const es = {
  address,
  contacts,
  eConsult,
  misc,
  personal,
  photos,
  questions,
  validation,
  complete,
  status,
  welcome,
  discount
};
