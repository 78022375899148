import { FC, ReactNode, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingScreen } from '@joovence/ui/components/LoadingScreen/LoadingScreen';

import { SsrData } from '@/types/page';

import { RootState } from '@/store';
import { authActions } from '@/store/auth';
import { configActions } from '@/store/config';
import { eConsultActions } from '@/store/eConsult';

import { ENCRYPTED_UUID_COOKIE_KEY } from '@/utils/config';
import { STATUS } from '@/utils/vars';
import { getCookie } from '@/utils/cookie';

interface HandleAuthProps {
  children: ReactNode;
  ssrData: SsrData;
}

export const HandleAuth: FC<HandleAuthProps> = ({ children, ssrData }) => {
  const dispatch = useDispatch();
  const { query } = useRouter();

  const ready = useSelector(
    ({ config }: RootState) => config.status === STATUS.SUCCEEDED
  );

  useEffect(() => {
    if (!ready) {
      let uuid: string | undefined;

      dispatch(authActions.authRequested());

      if (!ssrData?.config) {
        dispatch(configActions.configFetchRequested());
      } else {
        dispatch(configActions.configFetchSucceeded(ssrData.config));
      }

      if (ssrData?.eConsult) {
        dispatch(eConsultActions.eConsultFetchSucceeded(ssrData.eConsult));
      }

      const uuidInCookie = getCookie(ENCRYPTED_UUID_COOKIE_KEY);
      const uuidInQuery = query?.key;

      if (uuidInQuery) {
        uuid = uuidInQuery as string;
      } else if (uuidInCookie) {
        uuid = JSON.parse(uuidInCookie);
      }

      if (!ssrData?.eConsult && uuid) {
        dispatch(
          eConsultActions.eConsultFetchRequested({
            encryptedUuid: uuid
          })
        );
      }
    }
  }, [ready, ssrData]);

  return (
    <>
      <LoadingScreen visible={!ready} />
      {ready && children}
    </>
  );
};
