export enum LANGUAGES {
  EN = 'en',
  FR = 'fr',
  ES = 'es',
  IT = 'it'
}

export const LANGUAGES_ARRAY = Object.values(LANGUAGES);

export enum ROUTES {
  welcome = '/',
  informations = '/informations',
  questions = '/questions',
  photos = '/photos',
  confirmation = '/confirmation',
  status = '/status'
}

export enum COUNTRIES {
  FR = 'FR',
  ES = 'ES',
  BE = 'BE',
  IT = 'IT'
}

export const COUNTRIES_ARRAY = Object.values(COUNTRIES);

export enum STATUS {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED'
}

export const UUID_IN_QUERY_KEY = 'key';

export const CSRF_URL = '/auth/csrf-cookie';

export const SSR_USER_COOKIE_KEY = 'ssr_user';
